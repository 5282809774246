import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Link, Box, Image, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "36px 0 80px 0",
	"quarkly-title": "HeroBlock",
	"md-padding": "36px 0 60px 0",
	"background": "#161618"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"empty-min-width": "64px",
			"empty-min-height": "64px",
			"empty-border-width": "1px",
			"empty-border-style": "solid",
			"empty-border-color": "LightGray",
			"flex-direction": "column",
			"display": "flex",
			"align-items": "flex-start",
			"lg-padding": "0px 90px 0px 0px",
			"md-width": "100%",
			"justify-content": "center",
			"width": "60%",
			"padding": "0px 180px 0px 0px",
			"md-padding": "0px 0px 0px 0px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"lg-text-align": "left",
			"lg-font": "normal 700 40px/1.2 \"Inter\", sans-serif",
			"md-font": "normal 700 28px/1.2 \"Inter\", sans-serif",
			"margin": "0px 0px 16px 0px",
			"color": "--white",
			"sm-font": "normal 500 30px/1.2 \"Inter\", sans-serif",
			"width": "max-content",
			"font": "normal 700 60px/1.2 \"inter\",sans-serif",
			"children": <>
				Keine Website?{" "}
				<br />
				Kein Problem!
			</>
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"font": "--base",
			"lg-text-align": "left",
			"lg-font": "normal 400 16px/1.5 \"Inter\", sans-serif",
			"md-font": "normal 400 14px/1.5 \"Inter\", sans-serif",
			"margin": "0px 0px 0px 0px",
			"color": "--white",
			"opacity": "1",
			"children": <>
				Sie benötigen eine moderne Website?{" "}
				<br />
				Wir schaffen Ihren Webauftritt.
			</>
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"lg-margin": "32px 0px 0px 0px",
			"md-width": "100%",
			"md-margin": "36px 0px 0px 0px",
			"display": "flex",
			"margin": "72px 0px 0px 0px"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"sm-margin": "0px 22px 0px 0px",
			"transition": "background-color 0.3s --transitionTimingFunction-easeInOut 0s",
			"href": "/login",
			"text-decoration-line": "initial",
			"sm-font": "normal 500 18px/1.6 \"Inter\", sans-serif",
			"md-font": "normal 500 16px/1.6 \"Inter\", sans-serif",
			"sm-padding": "15px 13px 15px 13px",
			"md-text-align": "center",
			"lg-margin": "0px 32px 0px 0px",
			"padding": "15px 30px 15px 30px",
			"border-radius": "10px",
			"lg-font": "normal 500 18px/1.6 \"Inter\", sans-serif",
			"md-padding": "15px 16px 15px 16px",
			"color": "--white",
			"margin": "0px 44px 0px 0px",
			"lg-padding": "15px 23px 15px 23px",
			"md-width": "50%",
			"font": "--lead",
			"border-width": "2px",
			"border-style": "solid",
			"hover-transition": "background-color --transitionDuration-normal ease 10ms",
			"background": "#ee4d2e",
			"border-color": "--color-pr0Orange",
			"hover-color": "--proweiss",
			"hover-border-color": "--color-proweiss",
			"hover-background": "--color-richtigesgrau",
			"children": "Leistungen"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"padding": "15px 30px 15px 30px",
			"font": "--lead",
			"border-radius": "10px",
			"border-width": "2px",
			"transition": "background-color 0.3s --transitionTimingFunction-easeInOut 0s",
			"lg-font": "normal 500 18px/1.6 \"Inter\", sans-serif",
			"border-style": "solid",
			"md-width": "50%",
			"md-font": "normal 500 16px/1.6 \"Inter\", sans-serif",
			"md-text-align": "center",
			"sm-padding": "15px 13px 15px 13px",
			"text-decoration-line": "initial",
			"background": "rgba(255, 5, 5, 0)",
			"margin": "0px 0px 0px 0px",
			"sm-font": "normal 500 18px/1.6 \"Inter\", sans-serif",
			"href": "/login",
			"lg-padding": "15px 23px 15px 23px",
			"md-padding": "15px 16px 15px 16px",
			"children": "Mehr erfahren",
			"border-color": "--color-zweitesorange",
			"color": "--pr0Orange",
			"hover-background": "--color-richtigesgrau",
			"hover-color": "--proweiss",
			"hover-border-color": "--color-proweiss"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"empty-border-style": "solid",
			"width": "45%",
			"display": "flex",
			"justify-content": "flex-end",
			"empty-border-width": "1px",
			"empty-min-height": "64px",
			"empty-border-color": "LightGray",
			"align-items": "center",
			"md-width": "100%",
			"md-order": "-1",
			"empty-min-width": "64px"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"lg-max-height": "400px",
			"width": "100% border-box",
			"max-height": "450px",
			"md-margin": "0px 0px 30px 0px",
			"lg-position": "relative",
			"lg-left": "-5px",
			"max-width": "100%",
			"hover-opacity": "1",
			"hover-transition": "all --transitionDuration-normal ease 20ms",
			"hover-transform": "translate(5px, 5px) skew(2deg, 0deg) scale3d(1.1, 1.1, 1.1)",
			"transition": "all --transitionDuration-normal ease 20ms",
			"src": "https://github.com/clemone210/website-data/blob/main/heroimg.png?raw=true"
		}
	}
};

const Hero = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override
			slot="SectionContent"
			flex-direction="row"
			position="relative"
			z-index="9"
			md-flex-wrap="wrap"
			md-z-index="7"
		/>
		<Box {...override("box")}>
			<Text {...override("text")} />
			<Text {...override("text1")} />
			<Box {...override("box1")}>
				<Link {...override("link")} />
				<Link {...override("link1")} />
			</Box>
		</Box>
		<Box {...override("box2")}>
			<Image {...override("image")} />
		</Box>
		{children}
	</Section>;
};

Object.assign(Hero, { ...Section,
	defaultProps,
	overrides
});
export default Hero;