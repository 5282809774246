import React from "react";
import { useOverrides, Override, SocialMedia } from "@quarkly/components";
import { Image, Box, Text, Link, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "20px 0 20px 0",
	"md-padding": "40px 0 40px 0",
	"height": "300p",
	"display": "flex",
	"background": "--color-richtigesgrau"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"empty-border-color": "LightGray",
			"flex-direction": "column",
			"md-justify-content": "center",
			"display": "flex",
			"padding": "16px 16px 16px 16px",
			"md-width": "100%",
			"empty-min-width": "64px",
			"empty-min-height": "64px",
			"empty-border-width": "1px",
			"empty-border-style": "solid",
			"width": "25%",
			"md-align-items": "center"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"src": "https://media4.giphy.com/media/jB57cmKYDKuNw68W1G/giphy.gif?cid=ecf05e47bdn712lfrowt7tw4260ukosgigcapr6o4hz3nz9y&rid=giphy.gif&ct=g",
			"margin": "0px 0px 24px 0px",
			"max-width": "171px",
			"align-self": "center"
		}
	},
	"socialMedia": {
		"kind": "SocialMedia",
		"props": {
			"discord": "clem.one#1462",
			"snapchat": "clemone543",
			"instagram": "https://instagram.com/clem.one",
			"facebook": "https://facebook.com/timohzbs"
		}
	},
	"socialMediaOverride": {
		"kind": "Override",
		"props": {
			"slot": "link",
			"border-radius": "50%",
			"color": "--light",
			"margin": "0 8px",
			"background": "--color-pr0Orange",
			"hover-background": "--color-pr0Orange",
			"hover-transition": "all --transitionDuration-fast ease 10ms",
			"hover-transform": "scale(1.1)",
			"transition": "all --transitionDuration-fast ease 10ms",
			"children": <div />
		}
	},
	"socialMediaOverride1": {
		"kind": "Override",
		"props": {
			"slot": "icon",
			"color": "--proweiss"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"empty-min-width": "64px",
			"empty-min-height": "64px",
			"empty-border-color": "LightGray",
			"width": "25%",
			"display": "flex",
			"md-width": "100%",
			"md-justify-content": "center",
			"empty-border-width": "1px",
			"empty-border-style": "solid",
			"flex-direction": "column",
			"padding": "16px 16px 16px 16px",
			"md-align-items": "center"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"color": "--white",
			"font": "--lead",
			"margin": "0px 0px 20px 0px",
			"children": "NAVIGATION"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"text-decoration-line": "initial",
			"transition": "opacity 0.3s ease 0s",
			"hover-opacity": "1",
			"margin": "0px 0px 10px 0px",
			"font": "--base",
			"color": "--white",
			"opacity": ".8",
			"href": "/index",
			"children": "Startseite"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"opacity": ".8",
			"text-decoration-line": "initial",
			"transition": "opacity 0.3s ease 0s",
			"hover-opacity": "1",
			"margin": "0px 0px 10px 0px",
			"font": "--base",
			"href": "#",
			"color": "--white",
			"children": "Projekte"
		}
	},
	"link2": {
		"kind": "Link",
		"props": {
			"opacity": ".8",
			"text-decoration-line": "initial",
			"transition": "opacity 0.3s ease 0s",
			"hover-opacity": "1",
			"margin": "0px 0px 10px 0px",
			"font": "--base",
			"color": "--white",
			"href": "/leistungen",
			"children": "Leistungen"
		}
	},
	"link3": {
		"kind": "Link",
		"props": {
			"color": "--white",
			"opacity": ".8",
			"text-decoration-line": "initial",
			"transition": "opacity 0.3s ease 0s",
			"hover-opacity": "1",
			"margin": "0px 0px 0px 0px",
			"font": "--base",
			"children": "Kontakt",
			"href": "/kontakt1"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"empty-min-height": "64px",
			"empty-border-color": "LightGray",
			"width": "25%",
			"display": "flex",
			"flex-direction": "column",
			"md-justify-content": "center",
			"empty-min-width": "64px",
			"empty-border-style": "solid",
			"padding": "16px 16px 16px 16px",
			"md-width": "100%",
			"md-align-items": "center",
			"empty-border-width": "1px"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"color": "--white",
			"font": "--lead",
			"margin": "0px 0px 20px 0px",
			"children": "RECHTLICHES"
		}
	},
	"link4": {
		"kind": "Link",
		"props": {
			"opacity": ".8",
			"text-decoration-line": "initial",
			"transition": "opacity 0.3s ease 0s",
			"hover-opacity": "1",
			"margin": "0px 0px 10px 0px",
			"font": "--base",
			"href": "#",
			"color": "--white",
			"children": "Impressum"
		}
	},
	"link5": {
		"kind": "Link",
		"props": {
			"text-decoration-line": "initial",
			"transition": "opacity 0.3s ease 0s",
			"hover-opacity": "1",
			"margin": "0px 0px 10px 0px",
			"font": "--base",
			"href": "#",
			"color": "--white",
			"opacity": ".8",
			"children": "AGB's"
		}
	},
	"link6": {
		"kind": "Link",
		"props": {
			"color": "--white",
			"opacity": ".8",
			"text-decoration-line": "initial",
			"transition": "opacity 0.3s ease 0s",
			"hover-opacity": "1",
			"margin": "0px 0px 10px 0px",
			"font": "--base",
			"href": "#",
			"children": "Datenschutz"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"empty-min-height": "64px",
			"empty-border-style": "solid",
			"empty-border-color": "LightGray",
			"width": "25%",
			"padding": "16px 16px 16px 16px",
			"md-width": "100%",
			"empty-min-width": "64px",
			"empty-border-width": "1px",
			"display": "flex",
			"flex-direction": "column",
			"md-align-items": "center",
			"md-justify-content": "center"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"color": "--white",
			"font": "--lead",
			"margin": "0px 0px 20px 0px",
			"children": "NÜTZLICHES"
		}
	},
	"link7": {
		"kind": "Link",
		"props": {
			"color": "--white",
			"opacity": ".8",
			"text-decoration-line": "initial",
			"hover-opacity": "1",
			"margin": "0px 0px 10px 0px",
			"font": "--base",
			"href": "https://play.google.com/store",
			"transition": "opacity 0.3s ease 0s",
			"target": "_blank",
			"children": "Test"
		}
	},
	"link8": {
		"kind": "Link",
		"props": {
			"font": "--base",
			"href": "#",
			"color": "--white",
			"opacity": ".8",
			"text-decoration-line": "initial",
			"transition": "opacity 0.3s ease 0s",
			"hover-opacity": "1",
			"margin": "0px 0px 10px 0px",
			"children": "X"
		}
	},
	"link9": {
		"kind": "Link",
		"props": {
			"hover-opacity": "1",
			"margin": "0px 0px 10px 0px",
			"font": "--base",
			"color": "--white",
			"opacity": ".8",
			"text-decoration-line": "initial",
			"transition": "opacity 0.3s ease 0s",
			"target": "_blank",
			"children": "Motorrad-Dekore",
			"href": "https://www.rushracing.de/"
		}
	}
};

const Footer = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
		<Box {...override("box")}>
			<Image {...override("image")} />
			<SocialMedia {...override("socialMedia")}>
				<Override {...override("socialMediaOverride")} />
				<Override {...override("socialMediaOverride1")} />
			</SocialMedia>
		</Box>
		<Box {...override("box1")}>
			<Text {...override("text")} />
			<Link {...override("link")} />
			<Link {...override("link1")} />
			<Link {...override("link2")} />
			<Link {...override("link3")} />
		</Box>
		<Box {...override("box2")}>
			<Text {...override("text1")} />
			<Link {...override("link4")} />
			<Link {...override("link5")} />
			<Link {...override("link6")} />
		</Box>
		<Box {...override("box3")}>
			<Text {...override("text2")} />
			<Link {...override("link7")} />
			<Link {...override("link8")} />
			<Link {...override("link9")} />
		</Box>
		{children}
	</Section>;
};

Object.assign(Footer, { ...Section,
	defaultProps,
	overrides
});
export default Footer;