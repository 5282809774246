import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Box, Link, Section } from "@quarkly/widgets";
import QuarklycommunityKitMobileSidePanel from "./QuarklycommunityKitMobileSidePanel";
import { MdClose } from "react-icons/md";
const defaultProps = {
	"padding": "36px 0 36px 0",
	"quarkly-title": "Header",
	"height": "100px",
	"background": "#161618"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"empty-border-width": "1px",
			"empty-border-style": "solid",
			"width": "30%",
			"sm-width": "50%",
			"display": "flex",
			"align-items": "center",
			"empty-min-width": "64px",
			"empty-min-height": "64px",
			"empty-border-color": "LightGray",
			"font": "16px "
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "#ffffff",
			"font": "bold 30px \"Inter\", sans-serif",
			"height": "min-content",
			"children": <Link
				href="/index"
				color="--proweiss"
				text-decoration-line="initial"
				hover-transition="all --transitionDuration-fast --transitionTimingFunction-easeIn 10ms"
				hover-color="--pr0Orange"
				transition="all --transitionDuration-normal ease-out 10ms"
			>
				CLEM1
			</Link>
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"empty-min-height": "64px",
			"empty-border-width": "1px",
			"width": "70%",
			"sm-width": "50%",
			"align-items": "center",
			"empty-min-width": "64px",
			"empty-border-style": "solid",
			"empty-border-color": "LightGray",
			"display": "flex",
			"hover-color": "--pr0Orange"
		}
	},
	"quarklycommunityKitMobileSidePanel": {
		"kind": "QuarklycommunityKitMobileSidePanel",
		"props": {
			"md-z-index": "9",
			"onloadShow": false,
			"menuPosition": "full"
		},
		"overrides": {
			"Button": {
				"props": {
					"sm-width": "max-content",
					"sm-height": "max-content",
					"sm-min-width": "max-content",
					"sm-min-height": "max-content"
				}
			}
		}
	},
	"quarklycommunityKitMobileSidePanelOverride": {
		"kind": "Override",
		"props": {
			"slot": "Content",
			"align-items": "center",
			"padding": "16px 0px 16px 16px",
			"md-background": "--color-light",
			"md-z-index": "9",
			"md-justify-content": "flex-start",
			"background": "rgba(255, 255, 255, 0)",
			"justify-content": "center",
			"sm-background": "--color-richtigesgrau",
			"sm-max-height": "max-content",
			"sm-height": "auto",
			"sm-min-height": "max-content",
			"sm-padding": "0px 0px 0pxx 0px",
			"sm-overflow-x": "visible",
			"sm-overflow-y": "visible"
		}
	},
	"quarklycommunityKitMobileSidePanelOverride1": {
		"kind": "Override",
		"props": {
			"slot": "Children",
			"justify-content": "center",
			"display": "flex",
			"align-items": "center",
			"sm-height": "max-content",
			"sm-max-height": "max-content"
		}
	},
	"quarklycommunityKitMobileSidePanelOverride2": {
		"kind": "Override",
		"props": {
			"slot": "Button Text",
			"md-display": "none"
		}
	},
	"quarklycommunityKitMobileSidePanelOverride3": {
		"kind": "Override",
		"props": {
			"slot": "Button Icon",
			"md-color": "--white",
			"md-font": "36px sans-serif",
			"sm-align-content": "flex-start",
			"sm-align-items": "center",
			"sm-display": "flex",
			"sm-height": "max-content",
			"sm-justify-content": "center",
			"sm-width": "max-content",
			"sm-margin": "0px 5px 0px 0px"
		}
	},
	"quarklycommunityKitMobileSidePanelOverride4": {
		"kind": "Override",
		"props": {
			"slot": "Cross",
			"md-color": "--dark",
			"md-font": "36px sans-serif",
			"category": "md",
			"icon": MdClose,
			"sm-color": "#ffffff",
			"sm-margin": "20px 5px 0px 0px"
		}
	},
	"quarklycommunityKitMobileSidePanelOverride5": {
		"kind": "Override",
		"props": {
			"slot": "Wrapper",
			"md-z-index": "9"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"align-items": "center",
			"justify-content": "flex-end",
			"md-flex-direction": "column",
			"md-margin": "40px 0px 13px 0px",
			"md-font": "--headline4",
			"sm-margin": "20px 0px 13px 0px"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"font": "normal 600 18px/1.5 --fontFamily-googleInter",
			"text-decoration-line": "initial",
			"margin": "0px 20px 0px 20px",
			"md-margin": "0px 0px 13px 0px",
			"md-font": "--lead",
			"md-color": "--indigo",
			"hover-border-color": "#ffffff",
			"href": "/index",
			"hover-transform": "scale(1.1)",
			"hover-transition": "all --transitionDuration-normal ease 10ms",
			"transition": "all --transitionDuration-normal ease 10ms",
			"color": "--proweiss",
			"hover-color": "--pr0Orange",
			"sm-border-color": "--color-pr0Orange",
			"sm-color": "--proweiss",
			"children": "Startseite"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"md-margin": "0px 0px 13px 0px",
			"href": "#about",
			"font": "--base",
			"text-decoration-line": "initial",
			"hover-opacity": "1",
			"md-font": "--lead",
			"md-color": "--indigo",
			"margin": "0px 20px 0px 20px",
			"opacity": "0.8",
			"transition": "all --transitionDuration-normal ease 10ms",
			"hover-transition": "all --transitionDuration-normal ease 10ms",
			"hover-transform": "scale(1.1)",
			"color": "--proweiss",
			"hover-color": "--pr0Orange",
			"sm-color": "--proweiss",
			"children": "Projekte"
		}
	},
	"link2": {
		"kind": "Link",
		"props": {
			"margin": "0px 20px 0px 20px",
			"md-margin": "0px 0px 13px 0px",
			"md-font": "--lead",
			"font": "--base",
			"text-decoration-line": "initial",
			"opacity": "0.8",
			"hover-opacity": "1",
			"md-color": "--indigo",
			"transition": "all --transitionDuration-normal ease 10ms",
			"hover-transition": "all --transitionDuration-normal ease 10ms",
			"hover-transform": "scale(1.1)",
			"color": "--proweiss",
			"hover-color": "--pr0Orange",
			"href": "/leistungen",
			"sm-border-color": "--color-pr0Orange",
			"sm-color": "--proweiss",
			"children": "Leistungen"
		}
	},
	"link3": {
		"kind": "Link",
		"props": {
			"hover-opacity": "1",
			"md-font": "--lead",
			"md-color": "--indigo",
			"font": "--base",
			"opacity": "0.8",
			"md-margin": "0px 0px 13px 0px",
			"text-decoration-line": "initial",
			"margin": "0px 8px 0px 20px",
			"transition": "all --transitionDuration-normal ease 10ms",
			"hover-transition": "all --transitionDuration-normal ease 10ms",
			"hover-transform": "scale(1.1)",
			"color": "--proweiss",
			"hover-color": "--pr0Orange",
			"sm-color": "--proweiss",
			"children": "Kontakt",
			"href": "/kontakt1"
		}
	}
};

const Header = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" flex-direction="row" />
		<Box {...override("box")}>
			<Text {...override("text")} />
		</Box>
		<Box {...override("box1")}>
			<QuarklycommunityKitMobileSidePanel {...override("quarklycommunityKitMobileSidePanel")}>
				<Override {...override("quarklycommunityKitMobileSidePanelOverride")} />
				<Override {...override("quarklycommunityKitMobileSidePanelOverride1")} />
				<Override {...override("quarklycommunityKitMobileSidePanelOverride2")} />
				<Override {...override("quarklycommunityKitMobileSidePanelOverride3")} />
				<Override {...override("quarklycommunityKitMobileSidePanelOverride4")} />
				<Override {...override("quarklycommunityKitMobileSidePanelOverride5")} />
				<Box {...override("box2")}>
					<Link {...override("link")} />
					<Link {...override("link1")} />
					<Link {...override("link2")} />
					<Link {...override("link3")} />
				</Box>
			</QuarklycommunityKitMobileSidePanel>
		</Box>
		{children}
	</Section>;
};

Object.assign(Header, { ...Section,
	defaultProps,
	overrides
});
export default Header;